import styles from './MessageLoader.module.css'

const MessageLoader = () => {
  return (
    <div className={styles.root}>
      <span className={styles.loader}></span>
    </div>
  )
}

export default MessageLoader