import React from 'react'
import { LoadingButton } from '@mui/lab'
import { Button as ButtonBase, ButtonProps, ButtonTypeMap } from '@mui/material'


type LocalButtonProps = ButtonTypeMap<object, 'button'>['props'] & {
  loading?: boolean
  className?: string
  type?: 'button' | 'reset' | 'submit'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Button = <C extends React.ElementType>(
  props: LocalButtonProps & ButtonProps<C, { component?: C; buttonRef?: React.Ref<HTMLButtonElement> }>
) => {
  const { children, buttonRef, loading, className, size, ...rest } = props

  const Button = () => {
    if (loading) {
      return (
        <LoadingButton ref={buttonRef} loading={loading} className={className} size={size} {...rest}>
          {children}
        </LoadingButton>
      )
    }

    return (
      <ButtonBase ref={buttonRef} className={className} size={size} {...rest}>
        {children}
      </ButtonBase>
    )
  }

  return <Button />
}

export default Button
