import React from 'react'
import c from './Chat.module.css'

import { IMessage } from '../../ChatBot'
import { Message } from '../Message'
import { MessageLoader } from '../MessageLoader'

interface MessageProps {
  messages: IMessage[]
  isLoading?: boolean
}

const Chat: React.FC<MessageProps> = ({ messages, isLoading }) => {
  return (
    <div className={c.chatContainer}>
      {isLoading && <MessageLoader />}
      {messages.map((msg, index) => (
        <Message key={index} message={msg} />
      ))}
    </div>
  )
}

export default Chat
