import { create } from 'zustand'

interface AuthState {
  token: string | null; // Токен может быть строкой или null
  setToken: (newToken: string) => void;
  clearToken: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  token: sessionStorage.getItem('bearer'),
  setToken: (newToken: string) => set({ token: newToken }),
  clearToken: () => {
    sessionStorage.removeItem('bearer')
    set({ token: null })
  }
}))
