import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import styles from './Message.module.css'

import clsx from 'clsx'
import { IMessage } from '../../ChatBot'

export interface MessageProps {
  message: IMessage
}

const Message: React.FC<MessageProps> = ({ message }) => {
  switch (message.type) {
    case 'text':
      return (
        <div className={styles.messageContainer}>
          <div
            className={clsx(styles.textMessage, message.isResponse ? styles.responseMessage : styles.requestMessage)}
            
          >
            {/* {message.content} */}
            <div dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, '<br />') }}></div>
            <span className={styles.messageTime}>{message?.datetime}</span>
          </div>
        </div>
      )
    case 'image':
      return (
        <div className={styles.messageContainer}>
          <img className={styles.imageMessage} src={message.content} alt='Image' />
        </div>
      )
    case 'audio':
      return (
        <div className={styles.messageContainer}>
          <div className={styles.audioMessage}>
            <ReactAudioPlayer src={message.content} controls />
          </div>
        </div>
      )
    default:
      return null
  }
}

export default Message
