import React from 'react'
import { Collapse } from '@mui/material'

import { Button } from '../../../shared-ui'
import styles from './InputButtons.module.css'
import { IMessage } from '../../ChatBot'
import dayjs from 'dayjs'

interface IProps {
  buttons?: string[]
  isOpen?: boolean
  onSendMessage: (message: IMessage) => void
}

const InputButtons: React.FC<IProps> = ({ isOpen, buttons, onSendMessage }) => {
  return (
    <Collapse in={isOpen} orientation='vertical'>
      <div className={styles.container}>
        {buttons?.map((content, inx) => (
          <Button
            key={inx}
            variant='outlined'
            className={styles.button}
            onClick={() => onSendMessage({ type: 'text', content, datetime: dayjs().format('HH:mm') })}
          >
            {content}
          </Button>
        ))}
      </div>
    </Collapse>
  )
}

export default InputButtons
